import React from "react";
import { Locales } from "../../../localization/types";
import styles from "./styles.module.scss";
import classNames from "classnames";

export const getAdvantages = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				title: "Простая установка",
				description:
					"Скачай приложение на планшет и подключи гаджет к той же Wi-Fi сети, что и кассовый терминал.",
			},
			{
				title: "Экономия времени",
				description:
					"Меньше ошибок при оформлении заказов — выше скорость обслуживания.",
			},
			{
				title: "Всё прозрачно",
				description:
					"Гости могут проверить стоимость каждой позиции и итоговую сумму.",
			},
		];
	}

	if (locale === "kz-KZ") {
		return [
			{
				title: "Оңай орнату",
				description:
					"Қосымшаны планшетке жүктеп алыңыз және гаджетті кассалық терминал қосылып тұрған сол Wi-Fi желісіне қосыңыз.",
			},
			{
				title: "Уақытты үнемдеу",
				description:
					"Тапсырыстырды ресімдеу кезінде қателер аз болса - қызмет көрсету жылдамдығы жоғары болады.",
			},
			{
				title: "Барлығы ашық",
				description:
					"Қонақтар әр позицияның құнын және қорытынды соманы тексере алады.",
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				title: "Простая установка",
				description: (
					<>
						<p className={classNames(styles.slides_by, styles.slides_by__desktop)}>
							Скачай приложение на&nbsp;планшет и&nbsp;подключи гаджет к той же Wi-Fi сети, что и кассовый терминал.
						</p>
						<noindex className={classNames(styles.slides_by, styles.slides_by__tabletMobile)}>
							Подключается к планшетам <br />
							на&nbsp;базе Android и&nbsp;телевизорам на&nbsp;базе Android&nbsp;TV.
						</noindex>
						<noindex className={classNames(styles.slides_by, styles.slides_by__mobile)}>
							Подключается к планшетам на&nbsp;базе Android и&nbsp;телевизорам на&nbsp;базе Android&nbsp;TV.
						</noindex>
					</>
				),
			},
			{
				title: (
					<>
						<span className={classNames(styles.slides_by, styles.slides_by__desktop)}>Экономия времени</span>
						<noindex className={classNames(styles.slides_by, styles.slides_by__tablet)}>
							Всё в одном месте
						</noindex>
					</>
				),
				description: (
					<>
						<p className={classNames(styles.slides_by, styles.slides_by__desktop)}>
							Меньше ошибок при оформлении заказов — выше скорость обслуживания.
						</p>
						<noindex className={classNames(styles.slides_by, styles.slides_by__tablet)}>
							Подружим заказы из&nbsp;зала, приложения и&nbsp;с&nbsp;сайта: неважно, где гость оформил заказ.
						</noindex>
					</>
				),
			},
			{
				title: (
					<>
						<span className={classNames(styles.slides_by, styles.slides_by__desktop)}>Всё прозрачно</span>
						<noindex className={classNames(styles.slides_by, styles.slides_by__tablet)}>
							С пылу с жару
						</noindex>
					</>
				),
				description: (
					<>
						<p className={classNames(styles.slides_by, styles.slides_by__desktop)}>
							Гости могут проверить стоимость каждой позиции и итоговую сумму.
						</p>
						<noindex className={classNames(styles.slides_by, styles.slides_by__tablet)}>
							Заказ попадает к&nbsp;гостю вовремя, свежим и горячим &mdash;&nbsp;гость доволен.
						</noindex>
					</>
				),
			},
		];
	}

	return [
		{
			title: "Простая установка",
			description:
				"Скачай приложение на планшет и подключи гаджет к той же Wi-Fi сети, что и кассовый терминал.",
		},
		{
			title: "Экономия времени",
			description:
				"Меньше ошибок при оформлении заказов — выше скорость обслуживания.",
		},
		{
			title: "Всё прозрачно",
			description:
				"Гости могут проверить стоимость каждой позиции и итоговую сумму.",
		},
	];
};
