import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { externalLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: "Экран покупателя",
			desktopTitle: "Проверим заказ?",
			subTitle:
				"Планшет размещается на кассе дисплеем к покупателю — тот видит все блюда и модификаторы, которые сотрудник добавляет в заказ, и сумму к оплате.",
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/cd-presentation_kz.png"
					alt="экран покупателя в кафе"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				appstore: {
					href: externalLinks.appstoreCustomerDisplay,
					alt: "Appstore",
				},
				googleplay: {
					href: externalLinks.googlePlayCustomerDisplay,
					alt: "Google Play Market",
				},
			},
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Сатып алушының экраны",
			desktopTitle: "Тапсырысты тексереміз бе?",
			subTitle:
				"Планшет кассада дисплейін сатып алушыға қаратып орналастырылады. — ол қызметкер тапсырысқа қосып отырған барлық тағамдар мен модификаторларды және төлеу сомасын көріп тұрады.",
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/cd-presentation_kz.png"
					alt="экран покупателя в кафе"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				appstore: {
					href: externalLinks.appstoreCustomerDisplay,
					alt: "Appstore",
				},
				googleplay: {
					href: externalLinks.googlePlayCustomerDisplay,
					alt: "Google Play Market",
				},
			},
		}
	}

	if (locale === 'ru-BY') {
		return {
			pageName: "Экран покупателя",
			desktopTitle: "Проверим заказ?",
			subTitle:
				"Планшет размещается на кассе дисплеем к покупателю — тот видит все блюда и модификаторы, которые сотрудник добавляет в заказ, и сумму к оплате.",
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/cd-presentation_by.png"
					alt="экран покупателя в кафе"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				appstore: {
					href: externalLinks.appstoreCustomerDisplay,
					alt: "Appstore",
				},
				googleplay: {
					href: externalLinks.googlePlayCustomerDisplay,
					alt: "Google Play Market",
				},
			},
		}
	}

	return {
		pageName: "Экран покупателя",
		desktopTitle: "Давайте проверим заказ",
		subTitle:
			"Планшет размещается на кассе дисплеем к покупателю — тот видит все блюда и модификаторы, которые сотрудник добавляет в заказ, и сумму к оплате.",
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/cd-presentation.png"
				alt="экран покупателя в кафе"
				objectFit="contain"
				objectPosition="center"
				placeholder="blurred"
				quality={90}
			/>
		),
		storeBadges: {
			appstore: {
				href: externalLinks.appstoreCustomerDisplay,
				alt: "Appstore",
			},
			googleplay: {
				href: externalLinks.googlePlayCustomerDisplay,
				alt: "Google Play Market",
			},
		},
	}
}
