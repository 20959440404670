import { Locales } from "../../../localization/types";
import PresentationImg from "../ProductPresentation/assets/cd-presentation.png";
import PresentationImgKz from "../ProductPresentation/assets/cd-presentation_kz.png";
import PresentationImgBy from "../ProductPresentation/assets/cd-presentation_by.png";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getCustomerDisplayMeta = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			title: "Экран покупателя в системе автоматизации ресторана | Quick Resto Казахстан",
			metaTags: [
				{
					httpEquiv: "X-UA-Compatible",
					content: "ie=edge",
				},
				{
					name: "description",
					content:
						"Информация о добавленных в заказ блюдах и модификаторах и сумме к оплате → Простая установка приложения на планшет → Контроль работы персонала со стороны гостя → Дополнительные рекламные возможности → Быстрая авторизация гостя с помощью телефона и камеры планшета. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:description",
					content:
						"Информация о добавленных в заказ блюдах и модификаторах и сумме к оплате → Простая установка приложения на планшет → Контроль работы персонала со стороны гостя → Дополнительные рекламные возможности → Быстрая авторизация гостя с помощью телефона и камеры планшета. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}customer-display/`,
				},
				{
					property: "og:title",
					content:
						"Экран покупателя в системе автоматизации ресторана | Quick Resto Казахстан",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${PresentationImgKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}customer-display/`,
		};
	}

	if (locale === "ru-BY") {
		return {
			title: "Экран покупателя в системе автоматизации ресторана | Quick Resto Беларусь",
			metaTags: [
				{
					httpEquiv: "X-UA-Compatible",
					content: "ie=edge",
				},
				{
					name: "description",
					content:
						"Информация о добавленных в заказ блюдах и модификаторах и сумме к оплате → Простая установка приложения на планшет → Контроль работы персонала со стороны гостя → Дополнительные рекламные возможности → Быстрая авторизация гостя с помощью телефона и камеры планшета. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:description",
					content:
						"Информация о добавленных в заказ блюдах и модификаторах и сумме к оплате → Простая установка приложения на планшет → Контроль работы персонала со стороны гостя → Дополнительные рекламные возможности → Быстрая авторизация гостя с помощью телефона и камеры планшета. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}customer-display/`,
				},
				{
					property: "og:title",
					content:
						"Экран покупателя в системе автоматизации ресторана | Quick Resto Беларусь",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${PresentationImgBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}customer-display/`,
		};
	}

	return {
		title: "Экран покупателя в системе автоматизации ресторана | Quick Resto",
		metaTags: [
			{
				httpEquiv: "X-UA-Compatible",
				content: "ie=edge",
			},
			{
				name: "description",
				content:
					"Приложение позволит отображать заказы клиентов на POS-терминале. Поддерживает слайд-шоу и QR-коды для оплаты. Совместимо с iOS и Android.",
			},
			{
				property: "og:description",
				content:
					"Приложение позволит отображать заказы клиентов на POS-терминале. Поддерживает слайд-шоу и QR-коды для оплаты. Совместимо с iOS и Android.",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}customer-display/`,
			},
			{
				property: "og:title",
				content:
					"Экран покупателя в системе автоматизации ресторана | Quick Resto",
			},
			{
				property: "og:image",
				content: `${externalLinks.quickrestoRU.href}${PresentationImg}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}customer-display/`,
	};
};
