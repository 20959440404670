import React from 'react'

export const getSeoText = () => (
  <div>
    <p>Какие функции предлагает Quick Resto Display?</p>
    Quick Resto Display (Экран покупателя) предлагает функции, такие как отображение заказов в ресторане в реальном
    времени, возможность просмотра стоимости каждой позиции и итоговой суммы. Экран заказов с поддержкой слайд-шоу
    также поддерживает QR-коды для оплаты. Это делает табло покупателя удобными инструментами для улучшения
    обслуживания клиентов.

    <p>Можно ли настраивать отображение информации на экране клиента?</p>
    Да, экран клиента Quick Resto (цифровой дисплей для клиентов) позволяет настраивать отображение информации.
    Пользователи могут добавлять в электронное меню для клиентов логотипы, приветственные сообщения и специальные
    предложения, делая интерфейс более персонализированным.

    <p>С какими POS-системами совместимо приложение?</p>
    С приложением для отображения заказов можно использовать практически любой POS-терминал для ресторана. Это
    делают возможным Quick Resto POS-интеграция и POS-экран для отображения заказов.

    <p>Работает ли программное обеспечение для ресторанов Quick Resto Display на iOS и Android?</p>
    Да, Дисплей покупателя Quick Resto работает на обеих платформах – iOS и Android, что позволяет использовать
    приложение для отображения заказов на планшете с любой операционной системой.

    <p>Какие минимальные требования к операционной системе?</p>
    Минимальные требования к операционной системе для работы приложения для отображения заказов зависят от версии
    iOS и Android. Рекомендуется использовать последние обновления для оптимальной работы.

    <p>Как настроить приветственное сообщение и логотип на экране?</p>
    Настраиваемый для ресторанов позволяет легко добавлять приветственные сообщения и логотипы через приложение для
    управления рестораном, доступное в облачном бэк-офисе.

    <p>Можно ли отображать QR-коды для оплаты?</p>
    Да, экран заказа с QR-кодами поддерживается приложением, что упрощает процесс быстрой оплаты для клиентов.

    <p>Поддерживает ли приложение для ресторана Quick Resto несколько языков?</p>
    Приложение для кафе и ресторанов поддерживает несколько языков. Благодаря этому система управления заказами
    может быть использована и в международных ресторанах.

    <p>Как установить дисплей заказа для ресторанов Quick Resto на мое устройство?</p>
    Для установки Quick Resto Display скачайте приложение для кафе и ресторанов на ваше устройство и подключите его
    к той же Wi-Fi сети, что и POS-система с клиентским дисплеем для ресторана.

    <p>Как часто выпускаются обновления и что они включают?</p>
    Обновления для Quick Resto Display выпускаются регулярно и включают новые функции, улучшения производительности
    и исправления ошибок. Благодаря этому система отображения заказов всегда показывает актуальную информацию.

    <p>Есть ли руководство пользователя или поддержка для настройки приложения?</p>
    Да, руководство пользователя и техническая поддержка ресторанов доступны для помощи в настройке приложения. Вы
    можете связаться с технической поддержкой при возникновении проблем.

    <p>Как связаться с технической поддержкой при возникновении проблем?</p>
    Техническая поддержка ресторана доступна через онлайн-чат, службу поддержки по телефону и электронную почту.

    <p>Есть ли онлайн-чат или служба поддержки по телефону?</p>
    Да, Quick Resto предоставляет онлайн-чат и службу поддержки по телефону для быстрого решения любых возникающих
    вопросов.

    <p>Есть ли примеры успешного использования Quick Resto Display в ресторанах?</p>
    Да, есть множество примеров успешного использования Quick Resto Display в ресторанах, где приложение для
    управления заказами и POS-дисплей для клиентов значительно улучшили обслуживание клиентов и повысили
    эффективность работы.

  </div>
)
