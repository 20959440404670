import React from 'react'
import classNames from 'classnames'
import DefaultLayout from '../layout/default'
import ProductPresentation from '../pagesSections/customer-display/ProductPresentation'
import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'

import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import { getCustomerDisplayMeta } from '../pagesSections/customer-display/meta'

import { getAdvantages } from '../pages-data/_V2/customer-display/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { getFeaturesData } from '../pages-data/_V2/customer-display/features'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/customer-display.module.scss'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import ContentFeatures from '../pagesSections/customer-display/ContentFeatures'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import SeoText from "../components/_V2/SeoText";
import { getSeoText } from "../pages-data/_V2/customer-display/seotext";

export default function PageCustomerDisplay() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getCustomerDisplayMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        className={classNames(
				  styles.customerDisplay__advantages,
				  pageStyles.pageSection,
        )}
        items={getAdvantages(localizationContext.locale)}
      />

      <ContentFeatures className={pageStyles.pageSection} />

      <SimpleFeatures data={getFeaturesData(localizationContext.locale)} className={pageStyles.pageSection} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
