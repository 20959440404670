import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					tabName: "Синхронизация контента",
					desktopTitle: "Обновляй за пару кликов",
					desktopContent:
						"Загружаешь блюдо через бэк-офис — оно появляется сразу во всей системе: \n на сайте, в приложении и на экране для гостя.",
					mediaContent: (
						<div className={styles.feature1Media}>
							<StaticImage
								className={styles.feature1Media__image1}
								src="./assets/cd-content-1_kz.png"
								alt={"экран для костя в кофейне"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={95}
							/>
							<StaticImage
								className={styles.feature1Media__image2}
								src="./assets/cd-content-2_kz.png"
								alt={"экран для костя в кофейне"}
								// width={280}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={95}
							/>
							<StaticImage
								className={styles.feature1Media__image3}
								src="./assets/cd-content-3_kz.webp"
								alt={"экран для костя в кофейне"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={95}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Идентификация гостя",
					desktopTitle: "Обойдёмся без номера",
					desktopContent:
						"Гость просто показывает карту лояльности или приложение на своём смартфоне, \n и камера дисплея считывает QR-код или штрих-код.",
					mediaContent: (
						<StaticImage
							className={styles.feature2Media}
							src="./assets/cd-guest_kz.webp"
							alt={"мобильное приложение для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Дополнительная реклама",
					desktopTitle: "Используй паузы",
					desktopContent:
						"На планшеты с экраном покупателя можно загрузить видео — оно будет \nвоспроизводиться на экране, когда там не отображается информация о заказе.",
					mediaContent: (
						<StaticImage
							className={styles.feature3Media}
							src="./assets/cd-ad_kz.webp"
							alt={"реклама на дисплее покупателя"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent?: JSX.Element
			}>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					tabName: "Контентті синхрондау",
					desktopTitle: "Екі кликпен жаңартыңыз",
					desktopContent:
						"Тағамды бэк-офис арқылы жүктейсіз - ол бүкіл жүйеде бірден пайда болады:\nсайтта, қосымшада және қонаққа арналған экранда.",
					mediaContent: (
						<div className={styles.feature1Media}>
							<StaticImage
								className={styles.feature1Media__image1}
								src="./assets/cd-content-1_kz.png"
								alt={"экран для костя в кофейне"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={95}
							/>
							<StaticImage
								className={styles.feature1Media__image2}
								src="./assets/cd-content-2_kz.png"
								alt={"экран для костя в кофейне"}
								// width={280}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={95}
							/>
							<StaticImage
								className={styles.feature1Media__image3}
								src="./assets/cd-content-3_kz.webp"
								alt={"экран для костя в кофейне"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={95}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Қонақты сәйкестендіру",
					desktopTitle: "Нөмірсіз істі тындырамыз",
					desktopContent:
						"Қонақ жай ғана өз смартфонында адалдық картасын немесе қосымшаны көрсетеді,\nжәне дисплей камерасы QR-кодты немесе штрих-кодты оқып алады.",
					mediaContent: (
						<StaticImage
							className={styles.feature2Media}
							src="./assets/cd-guest_kz.webp"
							alt={"мобильное приложение для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Қосымша жарнама",
					desktopTitle: "Үзілістерді пайдаланыңыз",
					desktopContent:
						"Сатып алушы экраны бар планшеттерге видео жү ктеуге болады - ол экранда тапсырыс туралы ақпарат көрсетілмеген кезде жаңғыртылады.",
					mediaContent: (
						<StaticImage
							className={styles.feature3Media}
							src="./assets/cd-ad_kz.webp"
							alt={"реклама на дисплее покупателя"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent?: JSX.Element
			}>,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					tabName: "Синхронизация контента",
					desktopTitle: "Обновляй за пару кликов",
					desktopContent:
						"Загружаешь блюдо через бэк-офис — оно появляется сразу во всей системе: \n на сайте, в приложении и на экране для гостя.",
					mediaContent: (
						<div className={styles.feature1Media}>
							<StaticImage
								className={styles.feature1Media__image1}
								src="./assets/cd-content-1_by.png"
								alt={"экран для костя в кофейне"}
								objectFit={"contain"}
								placeholder={"blurred"}
							/>
							<StaticImage
								className={styles.feature1Media__image2}
								src="./assets/cd-content-2_by.png"
								alt={"экран для костя в кофейне"}
								// width={280}
								objectFit={"contain"}
								placeholder={"blurred"}
							/>
							<StaticImage
								className={styles.feature1Media__image3}
								src="./assets/cd-content-3_by.webp"
								alt={"экран для костя в кофейне"}
								objectFit={"contain"}
								placeholder={"blurred"}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Идентификация гостя",
					desktopTitle: "Обойдёмся без номера",
					desktopContent:
						"Гость просто показывает карту лояльности или приложение на своём смартфоне, \n и камера дисплея считывает QR-код или штрих-код.",
					mediaContent: (
						<StaticImage
							className={styles.feature2Media}
							src="./assets/cd-guest_by.webp"
							alt={"мобильное приложение для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Дополнительная реклама",
					desktopTitle: "Заполняй паузы",
					desktopContent:
						"На планшеты с экраном покупателя можно загрузить видео — оно будет \nвоспроизводиться на экране, когда там не отображается информация о заказе.",
					mediaContent: (
						<StaticImage
							className={styles.feature3Media}
							src="./assets/cd-ad_by.webp"
							alt={"реклама на дисплее покупателя"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent?: JSX.Element
			}>,
		}
	}

	return {
		features: [
			{
				tabName: "Синхронизация контента",
				desktopTitle: "Обновляй за пару кликов",
				desktopContent:
					"Загружаешь блюдо через бэк-офис — оно появляется сразу во всей системе: \n на сайте, в приложении и на экране для гостя.",
				mediaContent: (
					<div className={styles.feature1Media}>
						<StaticImage
							className={styles.feature1Media__image1}
							src="./assets/cd-content-1.webp"
							alt={"экран для костя в кофейне"}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
						<StaticImage
							className={styles.feature1Media__image2}
							src="./assets/cd-content-2.webp"
							alt={"экран для костя в кофейне"}
							// width={280}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
						<StaticImage
							className={styles.feature1Media__image3}
							src="./assets/cd-content-3.webp"
							alt={"экран для костя в кофейне"}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
					</div>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
			{
				tabName: "Идентификация гостя",
				desktopTitle: "Обходись без номера телефона",
				desktopContent:
					"Гость просто показывает карту лояльности или приложение на своём смартфоне, \n и камера дисплея считывает QR-код или штрих-код.",
				mediaContent: (
					<StaticImage
						className={styles.feature2Media}
						src="./assets/cd-guest.webp"
						alt={"мобильное приложение для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
			{
				tabName: "Дополнительная реклама",
				desktopTitle: "Заполняй паузы",
				desktopContent:
					"На планшеты с экраном покупателя можно загрузить видео — оно будет \nвоспроизводиться на экране, когда там не отображается информация о заказе.",
				mediaContent: (
					<StaticImage
						className={styles.feature3Media}
						src="./assets/cd-ad.webp"
						alt={"реклама на дисплее покупателя"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
		] as Array<{
			tabName: string

			// Заголовки могут быть для разных раскладок
			desktopTitle: string
			tabletTitle?: string
			mobileTitle?: string

			// Текстовый контент может быть для разных
			desktopContent: string
			tabletContent?: string
			mobileContent?: string

			detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

			mediaContent: JSX.Element
			backdropContent?: JSX.Element
		}>,
	}
}
