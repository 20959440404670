import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../common/links"
import { Locales } from "../../../localization/types";

export const getFeaturesData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				category: "Экран электронной очереди",
				title: "Держи внимание гостя и после заказа",
				description: (
					<p>
						Как ещё обратить внимание гостя на новинки и&nbsp;спецпредложения? С
						помощью электронной очереди&nbsp;заказов.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/cd-feature-1_kz.webp"
						alt="экран электронной очереди"
						breakpoints={[440, 720, 850, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 720px, (max-width: 1319px) 850px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "green",

				actionText: "Подробнее",
				actionLink: pagesLinks.line.href,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				category: "Электрондық кезек экраны",
				title: "Қонақтың назарын тапсырыстан кейін де ұстап тұрыңыз",
				description: (
					<p>
						Қонақтың назарын жаңалытар мен арнайы
						ұсыныс тарға тағы қалай аударуға болады? Электрондық тапсырыстар кезегі көмегімен
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/cd-feature-1_kz.webp"
						alt="экран электронной очереди"
						breakpoints={[440, 720, 850, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 720px, (max-width: 1319px) 850px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "green",

				actionText: "Толығырақ",
				actionLink: pagesLinks.line.href,
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				category: "Экран электронной очереди",
				title: "Держи внимание гостя и после заказа",
				description: (
					<p>
						Как ещё обратить внимание гостя на новинки и&nbsp;спецпредложения? С
						помощью электронной очереди&nbsp;заказов.
					</p>
				),

				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/cd-feature-1_by.webp"
						alt="экран электронной очереди"
						breakpoints={[440, 720, 850, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 720px, (max-width: 1319px) 850px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "green",

				actionText: "Подробнее",
				actionLink: pagesLinks.line.href,
			},
		]
	}

	return [
		{
			category: "Экран электронной очереди",
			title: "Допродажи тем, кто ждёт заказ",
			description: (
				<p>
					Как ещё обратить внимание гостя на новинки и&nbsp;спецпредложения? С
					помощью электронной очереди&nbsp;заказов.
				</p>
			),

			img: (
				<StaticImage
					className={styles.image1}
					src="./assets/cd-feature-1.webp"
					alt="экран электронной очереди"
					breakpoints={[440, 720, 850, 630]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 720px, (max-width: 1319px) 850px, (min-width: 1320px) 630px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.feature1,
			theme: "green",

			actionText: "Подробнее",
			actionLink: pagesLinks.line.href,
		},
	]
}
